import React from 'react'
import './Layout.css'
import { useTranslation } from 'react-i18next'

const ErrorMessage = (props) => {
  const { t } = useTranslation()
  const error = props.error
  return (
    <div className='error-container'>
      <p className='error-message'>{t(error)}</p>
    </div>
  )
}

export default ErrorMessage
