import axios from 'axios'
require('dotenv').config()

const loginService = async data => {
  var loginResponse = {
    resultCode: 0
  }

  try {
    data.token = getToken()
    data.linkToken = getLinkToken()
    const url = `https://${process.env.REACT_APP_LINE_BACKEND_URL}/user/link`

    const response = await axios.post(url, data)
    console.log('login', response)

    if (response.status === 200) {
      const responseData = response.data.code ? response.data : JSON.parse(response.data.body)
      console.log(responseData)
      switch (responseData.code) {
        case 'added':
          loginResponse.resultCode = 1
          loginResponse.redirectLink = responseData.redirect_link
          break
        case 'existing':
          loginResponse.resultCode = 2
          break
        default:
          loginResponse.resultCode = 0
      }
    }
    console.log(loginResponse)
    return { loginResponse }
  } catch (error) {
    console.log(error)
    var genericError = 'There was an unforseen issue, please try again later'
    loginResponse.errorMessage = genericError
    return { loginResponse }
  }
}

const getToken = () => {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const token = params.get('token')
  return token
}

const getLinkToken = () => {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const linkToken = params.get('linkToken')
  return linkToken
}

export default loginService
