import React from 'react'
import LoginState from './components/LoginState'
import './App.css'
function App () {
  return (
    <div className='App'>
      <LoginState />
    </div>
  )
}

export default App
