import React from 'react'
import Loader from 'react-loader-spinner'

import './Button.css'

const Button = props => {
  const loadingState = props.loadingState
  const loaderColor = props.loaderColor
  const style = props.className

  if (loadingState === false) {
    return (
      <div className='button-container'>
        <button type='submit' className={style}>
          {props.text}
        </button>
      </div>
    )
  } else {
    return (
      <div className='button-container'>
        <button onClick={event => event.preventDefault()} className={style}>
          <Loader type='Oval' color={loaderColor} height={30} width={30} />
        </button>
      </div>
    )
  }
}

export default Button
