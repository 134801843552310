import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import loginService from '../services/login'
import unlinkService from '../services/unlink'

import Button from './Button'
import ErrorMessage from './ErrorMessage'
import NewCustomerNotice from './NewCustomerNotice'
import ConnectedNotice from './ConnectedNotice'
import DisconnectNotice from './DisconnectNotice'

import './LoginState.css'

const LoginState = () => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors } = useForm()

  const [errorState, setErrorState] = useState(false)
  const [loadingState, setLoadingState] = useState(false)
  const [resultCode, setResultCode] = useState(0)

  const onSubmitLink = async data => {
    setLoadingState(true)
    const { loginResponse } = await loginService(data)
    setLoadingState(false)
    setResultCode(loginResponse.resultCode)

    if (loginResponse.errorMessage) {
      setErrorState(loginResponse.errorMessage)
    }
    if (loginResponse.redirectLink) {
      window.location.assign(loginResponse.redirectLink)
    }
  }

  const onSubmitUnlink = async data => {
    setLoadingState(true)
    const { unlinkResponse } = await unlinkService(data)
    setLoadingState(false)
    setResultCode(unlinkResponse.resultCode)

    if (unlinkResponse.errorMessage !== null) {
      setErrorState(unlinkResponse.errorMessage)
    }
  }

  return (
    <div>
      {// SHOW FORM WHEN CUSTOMER ISN'T LOGGED IN
      resultCode !== 1 && resultCode !== 2 && resultCode !== 3 && (
        <div>
          <form onSubmit={handleSubmit(onSubmitLink)}>
            <div>
              <p className='dark title'>{t('Connect to LINE')}</p>
              <div className='logo-container'>
                <img
                  className='logo'
                  src={require('../images/Default.png')}
                  alt='Default Logo'
                />
              </div>
              <div className='gold title'>
                <p>{t('Login to Leo and Lea')}</p>
                <p>{t('To finish linking')}</p>
              </div>
              {errorState && <ErrorMessage error={errorState} />}
              <div>
                <p className='input-title'>{t('email')}</p>
                <input
                  id='email'
                  type='email'
                  name='email'
                  className='form-input'
                  placeholder={t('Fill in your email please')}
                  ref={register({ required: true })}
                />
                <div className='input-error'>
                  {errors.email && <p>{t('this field is required')}</p>}
                </div>
              </div>
              <div className='password-input-container'>
                <p className='input-title'>{t('password')}</p>
                <input
                  id='password'
                  type='password'
                  name='password'
                  className='form-input'
                  placeholder={t('Fill in your password please')}
                  ref={register({ required: true })}
                />
                <div className='input-error'>
                  {errors.password && <p>{t('this field is required')}</p>}
                </div>
              </div>
              <Button
                loadingState={loadingState}
                text={t('Log in')}
                className='button cta-button'
                loaderColor='#fff'
              />
            </div>
          </form>
          <NewCustomerNotice />
        </div>
      )}

      {// SHOW ALREADY CONNECTED NOTICE IF CUSTOMER LINKS ACCOUNTS MORE THAN ONCE
      resultCode === 2 && (
        <div>
          <ConnectedNotice />
          {errorState && <ErrorMessage error={errorState} />}
          <form onSubmit={handleSubmit(onSubmitUnlink)}>
            <Button
              className='button secondary-button'
              loadingState={loadingState}
              text={t('Disconnect from Line')}
              loaderColor='#937e5d'
            />
            <p className='warning-notice'>{t('Unlinking notice')}</p>
          </form>
        </div>
      )}

      {// SHOW DISCONNECT NOTICE IF CUSTOMER CHOOSES TO UNLINK ACCOUNTS
      resultCode === 3 && <DisconnectNotice />}
    </div>
  )
}

export default LoginState
