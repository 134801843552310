import React from 'react'
import Button from './Button'
import './Layout.css'
import { useTranslation } from 'react-i18next'

const NewCustomerNotice = () => {
  const { t } = useTranslation()

  return (
    <div className='white-container'>
      <p className='gold sub-title'>
        {t('To people who dont have an account yet')}
      </p>
      <p className='info'>{t('Fill in information about your dog')}</p>
      <p className='info'>{t('Confirm a food plan for your dog')}</p>
      <p className='info'>{t('It takes just 2 minutes')}</p>
      <a href='https://leoandlea.com/create-dog-karte'>
        <Button
          className='button cta-button'
          loadingState={false}
          text={t('Register an account')}
        />
      </a>
    </div>
  )
}

export default NewCustomerNotice
