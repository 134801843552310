import React from 'react'
import './Layout.css'
import { useTranslation } from 'react-i18next'

const ConnectedNotice = () => {
  const { t } = useTranslation()

  return (
    <div>
      <p className='dark title'>{t('Connect to LINE')}</p>
      <div className='logo-container'>
        <img
          className='logo'
          src={require('../images/Connected.png')}
          alt='Connected Logo'
        />
      </div>
      <p className='gold title'>
        {t('Your line and l&l accounts are already linked')}
      </p>
    </div>
  )
}

export default ConnectedNotice
