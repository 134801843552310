import axios from 'axios'

const unlinkService = async data => {
  var unlinkResponse = {
    resultCode: 0
  }
  var genericError = 'There was an unforseen issue, please try again later'

  try {
    data.token = getToken()

    const url = `https://${process.env.REACT_APP_LINE_BACKEND_URL}/user/unlink`

    const response = await axios.post(url, data)
    if (response.status === 200) {
      unlinkResponse.resultCode = 3
      console.log(response)
    } else {
      unlinkResponse.errorMessage = genericError
    }
    return { unlinkResponse }
  } catch (error) {
    console.log(error)
    unlinkResponse.errorMessage = genericError
    return { unlinkResponse }
  }
}

const getToken = () => {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const linkToken = params.get('token')
  return linkToken
}

export default unlinkService
