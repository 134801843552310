import React from 'react'
import './Layout.css'
import { useTranslation } from 'react-i18next'

const DisconnectNotice = () => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='logo-container'>
        <img
          className='logo'
          src={require('../images/Disconnected.png')}
          alt='Disconnected Logo'
        />
      </div>
      <p className='gold title'>{t('Disconnected from LINE')}</p>
      <p className='dark'>{t('If you want to link again')}</p>
    </div>
  )
}

export default DisconnectNotice
